import PProviderService from '@/services/api/Provider/PProvider'
import RulesService from '@/services/rules'

export default {
  name: 'v-view-settings',
  data() {
    return {
      busy: false,
      dialogs: {
        image: false,
      },
      pProvider: {},
    }
  },
  methods: {

    /**
     * 
     */
    getProvider() {
      PProviderService.getById(this.$provider.id).then(pProvider => {
        this.$store.dispatch('ProviderModule/setData', {
          data: pProvider,
        })
        this.pProvider = pProvider
        console.log(pProvider, ' provider>>')
      })
    },

    /**
     * 
     */
    handleImageClick() {
      this.dialogs.image = true
    },

    /**
     * 
     */
    handleImageReset() {
      // this.pProvider = PProviderService.toLogo()
      // this.pProvider.id = this.$provider.id
      this.getProvider()
    },

    /**
     * 
     */
    async handleImageSubmit() {
      this.busy = true
      await PProviderService.logo(this.pProvider).then(this.handleLogoSuccess).then(this.getProvider).catch(this.handleLogoError)
      this.busy = false
    },

    /**
     * 
     */
    handleLogoError(payload) {
      PProviderService.translate(payload).then(this.$toast.error)
    },

    /**
     * 
     */
    handleLogoSuccess(payload) {
      PProviderService.translate({ response: payload }).then(this.$toast.success)
      this.dialogs.image = false
    },

    /**
     * 
     */
    handleProfileClick() {
      this.$router.push(this.$provider.permalink)
    },
  },
  mounted() {
    this.handleImageReset()
  },
  props: {

    /**
     * 
     */
    imageRules: {
      type: Array,
      default: () => [
        RulesService.required,
      ],
    },
  }
}